import { customAxios } from "../utils/customAxios";

const payloadEncryptionKeyService = {
    fetchPublicKey: async (): Promise<string> => {
        try {
            const response = await customAxios.get('SystemSetting/GetPayloadEncryptionKey');
            const publicKey: string = response.data.data;

            return publicKey;
        } catch (err) {
            throw err;
        }
    },
};

export default payloadEncryptionKeyService;
