import { useEffect, useState } from "react";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import Swal from "sweetalert2";

const UnableToLogOut : React.FC = () =>
{
    const localeCtx = useLocale();
    const [translationsLoading, setTranslationsLoading] =
      useState<boolean>(false);
    const [translations, setTranslations] = useState<
      Dictionary<string> | undefined
    >(localeCtx?.selectedLocale?.current.componentTranslations["unabletologout"]);

    useEffect(() => {
        const fetchTranslations = async () => {
          try {
            setTranslationsLoading(true);
            const resp = await localeCtx?.setComponentTranslations("unabletologout");
            setTranslations(resp);
          } catch (err) {
            setTranslations(
              localeCtx?.selectedLocale?.previous.componentTranslations["unabletologout"]
            );
            localeCtx?.setPreviousAppLocale("unabletologout");
            if (localeCtx?.localeSwitchFailed) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Couldn't Switch Language",
              });
            }
          } finally {
            setTimeout(() => {
              setTranslationsLoading(false);
            }, 100);
          }
        };
    
        if (!localeCtx?.selectedLocale?.current.componentTranslations["unabletologout"]) {
          fetchTranslations();
        }
      }, [localeCtx?.selectedLocale]);
    
      //#region Locale Translation Methods
      const fetchLabelKeyTranslation = (
        key: string,
        defaultValue: string
      ): string => {
        return translations && translations[key] ? translations[key] : defaultValue;
      };
      //#endregion Locale Translation Methods

    return(
        <div className="TemplateMessageOuter">
        <div className="TemplateMessageInner">
          <div className="TemplateMessageBox">
            <div className="errorContainer float-left w-100 text-center">
              <div className="errorImg">
                <div
                  className="notification-msg-i"
                  style={{
                    fontSize: "60px",
                    opacity: ".7",
                    padding: "0 0 30px 0",
                  }}
                >
                  <i className="bi bi-exclamation-triangle text-primary"></i>
                </div>
              </div>
              <div className="errorTxt">
                <div className="errorTxt-h fs-22 font-weight-semi p-b-20">
                  {translationsLoading
                    ? "Unable to Logout"
                    : fetchLabelKeyTranslation(
                      "UnableToLogoutText",
                      "Unable to Logout"
                    )
                  }
                </div>
                <div className="errorTxt-p fs-16 p-b-20">
                  {translationsLoading
                    ? "We were unable to log you out"
                    : fetchLabelKeyTranslation(
                      "UnableToLogoutMsg",
                      "We were unable to log you out"
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default UnableToLogOut;