import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout: React.FC = () => {
  return (
    <div className={`container-fluid px-0`}>
      <div className="row no-gutters min-vh-100">
        <div className="col-md-12">
          <Header />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
