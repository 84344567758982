import { Link } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
import defaultLogo from "../../assets/images/logo.png";
import useBranding from "../../hooks/useBranding";

const Header: React.FC = () => {
  const brandingCtx = useBranding();

  return (
    <div className="header-contain d-flex navbar-expand-lg navbar-light">
      {brandingCtx?.branding?.logos.find(
        (l) => l.name === "HeaderPrimaryLogo"
      )?.logoImageUrl && <div className="d-flex my-auto hdrTrk-logo navbar-logo">
          {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
          <img
            src={
              brandingCtx?.branding?.logos.find(
                (l) => l.name === "HeaderPrimaryLogo"
              )?.logoImageUrl
            }
            alt="logo"
          />
        </div>}
      <ul className="nav ml-lg-5 navbar-collapse justify-content-end">
        <li>
          <LanguageSelector />
        </li>
      </ul>
    </div>
  );
};

export default Header;
