import { createContext, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import brandingService from "../services/branding.service";
import { Branding } from "../types/branding/Branding";
import { BrandingThemeColor } from "../types/branding/BrandingThemeColor";

type BrandContextType = {
  branding?: ExpiringBranding;
};

interface ExpiringBranding extends Branding {
  expiryDateTime: Date;
}

const BrandContext = createContext<BrandContextType | null>(null);

export const BrandProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [branding, setBranding] = useLocalStorage<ExpiringBranding | undefined>(
    "branding",
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  const updateThemeColors = (themeColors: BrandingThemeColor[]) => {
    const r = document.querySelector(":root");
    themeColors.forEach((t) => {
      // @ts-ignore
      r?.style.setProperty(`--${t.colorKey}`, t.colorValue);
    });
  };

  const updateAppFavicon = (faviconImgUrl: string) => {
    let favicon = document.getElementById("favicon");
    if (favicon && favicon instanceof HTMLLinkElement) {
      favicon.href = faviconImgUrl;
    }
  };

  const setAppBranding = () => {
    const hostname = window.location.hostname;
    const appUrl =
      hostname === "localhost"
        ? `http://${hostname}:3000`
        : `https://${hostname}`;
    setLoading(true);
    brandingService
      .fetchBranding(appUrl)
      .then((response) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const augmentedBranding: ExpiringBranding = {
          ...response,
          expiryDateTime: tomorrow,
        };
        setBranding(augmentedBranding);
        updateThemeColors(response.themeColors);
        const faviconImgUrl = response.logos.find(
          (l) => l.name === "FaviconLogo"
        )?.logoImageUrl;
        if (faviconImgUrl) {
          updateAppFavicon(faviconImgUrl);
        } else {
          updateAppFavicon("/favicon.ico");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const currentDate = new Date();
    if (!branding || new Date(branding.expiryDateTime) < currentDate) {
      setAppBranding();
    } else {
      updateThemeColors(branding.themeColors);
      const faviconImgUrl = branding.logos.find(
        (l) => l.name === "FaviconLogo"
      )?.logoImageUrl;
      if (faviconImgUrl) {
        updateAppFavicon(faviconImgUrl);
      } else {
        updateAppFavicon("/favicon.ico");
      }
    }
  }, []);

  return (
    <BrandContext.Provider value={{ branding }}>
      {children}
    </BrandContext.Provider>
  );
};

export default BrandContext;
