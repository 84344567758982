import { FieldRenderProps } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { useState } from "react";

const CustomInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, type, ...others } = fieldRenderProps;
  const [typeProp, setTypeProp] = useState<string>(fieldRenderProps.type);
  return (
    <div className="d-flex position-relative">
      <Input
        type={
          fieldRenderProps.type === "password"
            ? typeProp
            : fieldRenderProps.type
        }
        style={
          fieldRenderProps.type === "password" ? { paddingRight: "40px" } : {}
        }
        {...others}
      />
      {fieldRenderProps.type === "password" &&
        fieldRenderProps.value &&
        fieldRenderProps?.value?.length !== 0 && (
          <i
            style={{
              position: "absolute",
              right: 10,
              top: 5,
            }}
            onClick={() => {
              setTypeProp(typeProp === "text" ? "password" : "text");
            }}
            className={typeProp !== "text" ? "bi bi-eye" : "bi bi-eye-slash"}
          ></i>
        )}
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export default CustomInput;
