import { Dictionary } from "../types/Dictionary";
import { LocalizationCode } from "../types/locale/LocalizationCode";
import { customAxios } from "../utils/customAxios";

const localeService = {
  GetLocalizationSupportedLanguage: async (): Promise<LocalizationCode[]> => {
    try {
      const response = await customAxios.get(`/locale/language`);
      const supportedLanguage: LocalizationCode[] = response.data;
      return supportedLanguage;
    } catch (err) {
      throw err;
    }
  },

  GetTranslationText: async (
    localeCode: string,
    keySource: string,
    keyGroup: string,
    keySubGroup: string | null,
    key: string | null
  ): Promise<any> => {
    try {
      const response = await customAxios.get(
        `/locale/translate?locale=${localeCode}&source=${keySource}&keyGroup=${keyGroup}&keySubGroup=${keySubGroup}&keyName=${key}`
      );
      const translation = response.data;
      return translation;
    } catch (err) {
      throw err;
    }
  },

  fetchSupportedLocales: async (): Promise<LocalizationCode[]> => {
    const response = await customAxios.get("/locale/language");
    const supportedLocales: LocalizationCode[] = response.data;

    return supportedLocales;
  },

  fetchTranslations: async (
    localeCode: string,
    keyGroup: string,
    keySubGroup?: string,
    key?: string
  ): Promise<Dictionary<string>> => {
    let requestQuery = "";
    if (keySubGroup) {
      requestQuery += `&keySubGroup=${keySubGroup}`;
    }
    if (key) {
      requestQuery += `&key=${key}`;
    }

    const response = await customAxios.get(
      `/locale/translate?locale=${localeCode}&source=WebApp&keyGroup=${keyGroup}${requestQuery}`
    );
    const translations: Dictionary<string> = response.data;

    return translations;
  },
};

export default localeService;
