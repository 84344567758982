import "./error.scss";
import erro404img from "../assets/images/404-error.gif";
import { Link } from "react-router-dom";
import useLocale from "../hooks/useLocale";
import { useEffect, useState } from "react";
import { Dictionary } from "../types/Dictionary";
import Swal from "sweetalert2";

const NotFoundError = () => {
  const localeCtx = useLocale();

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["resetpassword"]);

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setTranslationsLoading(true);
        const resp = await localeCtx?.setComponentTranslations("notfound");
        setTranslations(resp);
      } catch (err) {
        setTranslations(
          localeCtx?.selectedLocale?.previous.componentTranslations["notfound"]
        );
        localeCtx?.setPreviousAppLocale("notfound");
        if (localeCtx?.localeSwitchFailed) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Couldn't Switch Language",
          });
        }
      } finally {
        setTimeout(() => {
          setTranslationsLoading(false);
        }, 100);
      }
    };

    if (!localeCtx?.selectedLocale?.current.componentTranslations["notfound"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  return (
    <div className="errorContainer float-left w-100 text-center">
      <div className="errorImg">
        <img className="w-30" src={erro404img} alt={"Error! Not Found..."} />
      </div>
      <div className="errorTxt" style={{ position: "relative", top: "-25px" }}>
        <div className="errorTxt-h fs-30 font-weight-semi p-b-20">
          {translationsLoading
            ? "Oops page not found"
            : fetchLabelKeyTranslation(
                "NotFoundPageTitle",
                "Oops page not found"
              )}
        </div>
        <div className="errorTxt-p fs-16 p-b-20">
          {translationsLoading
            ? "The link you followed is probably broken, or the page has been removed"
            : fetchLabelKeyTranslation(
                "NotFoundPageMsg",
                "The link you followed is probably broken, or the page has been removed"
              )}
        </div>
      </div>
    </div>
  );
};

export default NotFoundError;
