import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { LocaleProvider } from "./contexts/LocaleProvider";
import { BrandProvider } from "./contexts/BrandProvider";
import { CLIENTID } from "./constants";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const hostname = window.location.hostname;
export const appUrl =
  hostname === "localhost" ? `http://${hostname}:3000` : `https://${hostname}`;
const clientInstance = new PublicClientApplication({
  auth: {
    clientId: `${CLIENTID}`,
    redirectUri: `${appUrl}/`,
    postLogoutRedirectUri: appUrl,
  },
});

root.render(
  <Router>
    <BrandProvider>
      <LocaleProvider>
        <MsalProvider instance={clientInstance}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </MsalProvider>
      </LocaleProvider>
    </BrandProvider>
  </Router>
);
